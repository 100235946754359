import { memo } from 'react'
import FundCard from '../FundCard'
import { FundInformation } from '@pimy-b2cweb/apiclient-customer-stmt'

export interface FundListProps {
  funds: FundInformation[]
  className?: string
  onFundCardClick?: (fund?: FundInformation) => void
  selectedFund: FundInformation | undefined
}

const FundList = memo(
  ({ funds, className = '', onFundCardClick, selectedFund }: FundListProps) => {
    console.log('funds: ', funds)

    return (
      <div className={`flex flex-col gap-2 ${className}`}>
        {funds.map((fund) => (
          <FundCard
            {...fund}
            onCardClick={
              !!onFundCardClick ? () => onFundCardClick(fund) : undefined
            }
            hasSelected={fund.hzFundCd === selectedFund?.hzFundCd}
            details={[]}
          />
        ))}
      </div>
    )
  }
)

export default FundList
