import { selectDelistingSessionState } from '@/stores/delisting.selectors'
import { setDelistingSelectedFund } from '@/stores/delisting'
import { useSelector } from 'react-redux'
import { ReactComponent as ChevronDownIco } from './assets/chevron-down.svg'
import {
  ButtonWithLoading,
  ResponseError,
  useResponseError,
} from '@pimy-b2cweb/frontend-lib'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { IconButton } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import FundCard from './components/FundCard'
import FundList from './components/FundList'
import {
  useQueryFundList,
  useQueryIsafScore,
  useMutationDelistingOtpInit,
  useMutationDelistingOtpInitProps,
} from '@/api'
import { OtpInitReqBodyPayloadIdTypeEnum } from '@pimy-b2cweb/apiclient-customer-auth'
import LoadingPage from '@/pages/Loading'
import { getErrorResponseCode } from '@/utils'
import { FundInformation } from '@pimy-b2cweb/apiclient-customer-stmt'
import { selectProfileSessionState } from '@/stores/profile.selectors'
import { authSessionSlice } from '@/stores/auth'

const { sessionDelistingOtp } = authSessionSlice.actions

export interface FundSelectionProps {
  goToIsaf: () => void
}
const FundSelection = ({ goToIsaf }: FundSelectionProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('fundSelectionPage')
  const { data: fundList, isLoading: isLoadingFundList } = useQueryFundList()
  const { data: isafData, isLoading: isLoadingIsafQuery } = useQueryIsafScore()

  const {
    mutate: mutateOtpInit,
    status: mutateOtpInitStatus,
    data: mutatedOtpInitData,
    error: mutatedOtpInitError,
    isLoading: mutateOtpIsLoading,
  } = useMutationDelistingOtpInit()

  const { recommendedFundCode } = useSelector(selectDelistingSessionState)
  const { fullName } = useSelector(selectProfileSessionState)

  const { idType = OtpInitReqBodyPayloadIdTypeEnum.Nric, idNo } = useSelector(
    selectProfileSessionState
  )

  const [isOpen, setIsOpen] = useState(false)

  const recommendedFund = useMemo(() => {
    if (!!fundList?.length && !!recommendedFundCode) {
      return fundList.find((fund) => fund.hzFundCd === recommendedFundCode)
    }
    return undefined
  }, [fundList, recommendedFundCode])

  const [selectedFund, setSelectedFund] = useState<
    FundInformation | undefined
  >()
  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()

  const otherFunds = useMemo(() => {
    if (!!fundList?.length && !!recommendedFundCode) {
      return fundList.filter((fund) => fund.hzFundCd !== recommendedFundCode)
    }
    return []
  }, [fundList, recommendedFundCode])

  useEffect(() => setSelectedFund(recommendedFund), [recommendedFund])

  const onFundCardClick = (fund?: FundInformation) => {
    setSelectedFund(fund)
  }

  const onClickSwitch = () => {
    console.log('selected Fund: ', selectedFund)

    const mutatingData: useMutationDelistingOtpInitProps = {
      idNo: idNo,
      idType: idType,
    }

    mutateOtpInit(mutatingData)
  }

  useEffect(() => {
    if (mutateOtpInitStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedOtpInitError)
      setResponseErrorAttrs(errMsg)
    }
    setResponseErrorAttrs(undefined)
    if (mutateOtpInitStatus === 'success' && !!mutatedOtpInitData) {
      console.log(mutatedOtpInitData)
      dispatch(
        setDelistingSelectedFund({
          selectedFundCode: selectedFund?.hzFundCd,
        })
      )
      dispatch(
        sessionDelistingOtp({
          ...mutatedOtpInitData,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateOtpInitStatus, mutatedOtpInitData, mutatedOtpInitError])

  return (
    <>
      {isLoadingFundList || isLoadingIsafQuery ? (
        <LoadingPage />
      ) : (
        <>
          {!!responseErrorAttrs && (
            <ResponseError>
              {t(responseErrorAttrs.i18nKey, {
                ns: responseErrorAttrs.ns,
              })}
            </ResponseError>
          )}
          <h1 className='text-pi-prussian-blue mb-6'>
            {t('an-easier-way-to-switch')}
          </h1>

          <p>
            <Trans
              i18nKey='thanks-for-being-loyal'
              t={t}
              values={{
                fullName,
                fundName: 'Principal DALI Equity Growth Fund',
              }}
              components={{
                B: <strong />,
              }}
            />
          </p>

          <p className='text-base'>
            {t('your-investment-appetite')}{' '}
            <span className='font-bold text-pi-principal-blue'>
              {isafData?.riskProfile.name}
            </span>
          </p>

          <div
            className='text-sm text-pi-principal-blue hover:text-pi-navy-blue underline cursor-pointer mb-4'
            onClick={() => goToIsaf()}
          >
            {t('reaccess-your-investment-appetite')}
          </div>

          {/* recommanded fund card */}
          <FundCard
            {...recommendedFund}
            hasSelected={recommendedFundCode === selectedFund?.hzFundCd}
            className='mb-6'
            onCardClick={() => onFundCardClick(recommendedFund)}
          />

          <div className='flex flex-row justify-between items-center mb-4'>
            <div className='text-xl text-pi-prussian-blue font-black'>
              {t('other-funds')}
            </div>
            <IconButton onClick={() => setIsOpen(!isOpen)}>
              <ChevronDownIco
                className={`w-6 h-6 transition duration-700 origin-center ${
                  isOpen ? 'rotate-180' : ''
                }`}
              />
            </IconButton>
          </div>

          {/* fund cards */}
          <FundList
            funds={otherFunds}
            className={`px-1 overflow-hidden h-auto transition-max-h duration-700 ${
              isOpen ? 'max-h-full py-1' : 'max-h-0 py-0'
            }`}
            selectedFund={selectedFund}
            onFundCardClick={onFundCardClick}
          />

          <p className='text-sm text-pi-gray-1 mb-10 mt-6'>{t('tnc-note')}</p>

          <ButtonWithLoading
            type='submit'
            fullWidth
            variant='contained'
            size='large'
            className='mb-6'
            disabled={!selectedFund}
            isLoading={mutateOtpIsLoading}
            onClick={onClickSwitch}
          >
            {t('agree-to-switch')}
          </ButtonWithLoading>
        </>
      )}
    </>
  )
}

export default FundSelection
