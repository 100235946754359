import { parsePhoneNumber } from 'awesome-phonenumber'

export const isNumber = (val: string) => {
  return /^\d+$/.test(val)
}

export const isNumberOrLetter = (val: string) => {
  return /^[a-zA-Z0-9]+$/.test(val)
}

export const delay = (sec = 3000) => {
  return new Promise((resolve) => {
    setTimeout(resolve, sec)
  })
}

// export const convertMask = (str: string) => str.replace(/\*/g, '•')

export enum ErrorResponseCodeEnum {
  CanNotResendOtp = 'can-not-resend-otp',
  InternalServerError = 'internal_server_error',
  InvalidChallenge = 'invalid_challenge',
  InvalidMobileNo = 'invalid_mobile_no',
  InvalidOtpCode = 'invalid_otp_code',
  InvalidRequest = 'invalid_request',
  InvalidSession = 'invalid_session',
  TooManyAttempts = 'too_many_attempts',
  TooManyOtpRequests = 'too_many_otp_requests',
  UnauthorizedIdNo = 'unauthorized_id_no',
  UnknownError = 'unknown_error',
}

export const getErrorResponseCode = (mutatedError: any) => {
  const _code = mutatedError?.response?.data?.code
  // if (!!_code && !/^HTTP-/.test(_code)) return _code
  if (Object.values(ErrorResponseCodeEnum).includes(_code)) return _code

  if (!!mutatedError?.response?.status) {
    switch (mutatedError.response.status) {
      case 400:
        return ErrorResponseCodeEnum.InvalidRequest
      case 403:
        return ErrorResponseCodeEnum.InvalidSession
      case 500:
      case 501:
        return ErrorResponseCodeEnum.InternalServerError
      default:
        return ErrorResponseCodeEnum.UnknownError
    }
  }
  if (mutatedError.code === 'ERR_NETWORK')
    return ErrorResponseCodeEnum.InternalServerError
  return ErrorResponseCodeEnum.UnknownError
}

export const getSessionStorage = (key: string) => {
  if (typeof window === 'undefined') return
  const data = sessionStorage.getItem(key)
  if (!data) return

  const jsonData = JSON.parse(data)

  return jsonData
}

export const setSessionStorage = (key: string, value: any) => {
  if (!key) return

  const jsonString = JSON.stringify(value)

  sessionStorage.setItem(key, jsonString)
}

export const removeSessionStorage = (key: string) => {
  sessionStorage.removeItem(key)
}

/* 
Function to format phone number
Input: Phone number with country code. e.g. 60123456789
Output: Phone number without cuntrycode. e.g. 123456789
 */
export const formatMobileNo = (mobileNo: string) => {
  let parsedNumber = mobileNo
  if (mobileNo.at(0) !== '+') parsedNumber = `+${mobileNo}`
  let parsed = parsePhoneNumber(parsedNumber)
  if (!parsed.valid) parsed = parsePhoneNumber(mobileNo, { regionCode: 'MY' })
  if (!parsed.valid) return mobileNo

  return (parsedNumber = parsed.number.significant)
}
